import { Anchor, Box, Text, Title } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import Checkbox from '../../../components/form/Checkbox/Checkbox';
import DateInput from '../../../components/form/DateInput';
import TextInput from '../../../components/form/TextInput';
import { useAppSelector } from '../../../hooks/store';
import useAppLogin from '../../../hooks/useAppLogin';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { setTempToken, setFinalAuthResponse, decodeTempToken } from '../../../store/profileLandingSlice';
import {
  useProfileLandingSaveProfileMutation,
  useProfileLandingCompleteMutation,
} from '../../../store/api/profileLandingApi';
import featureFlags from '../../../features';
import Marketing5RewardNotice from '../../../components/Rewards/Marketing5';

interface ProfileInfoFormValues {
  offersConsent: boolean;
  channelSms: boolean;
  channelEmail: boolean;
  birthDate: string;
  firstName: string;
  lastName: string;
}

const useStyles = createStyles((theme) => ({
  required: {
    color: theme.colors.errorDefault,
  },
}));

const ProfileInfo = (props: { nextStep: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveProfile, saveProfileQueryState] = useProfileLandingSaveProfileMutation();
  const [complete, completeQueryState] = useProfileLandingCompleteMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login] = useAppLogin();

  const { classes } = useStyles();

  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [offersConsent, setOffersConsent] = useState(false);
  const [channelSms, setChannelSms] = useState(false);
  const [channelEmail, setChannelEmail] = useState(false);
  const isLoading = saveProfileQueryState.isLoading || completeQueryState.isLoading;

  const tempToken = useAppSelector((state) => state.profileLanding.tempToken) || '';
  const firstName = useAppSelector((state) => state.profileLanding.firstName) || '';
  const lastName = useAppSelector((state) => state.profileLanding.lastName) || '';
  const personalCode = useAppSelector((state) => state.profileLanding.personalCode) || '';
  const birthDate = useAppSelector((state) => state.profileLanding.birthDate) || '';
  const email = useAppSelector((state) => state.profileLanding.email) || '';
  const phone = useAppSelector((state) => state.profileLanding.phone) || '';
  const hasPasswordAuth = useAppSelector((state) => state.profileLanding.hasPasswordAuth);
  const { accountId } = decodeTempToken(tempToken ?? '');

  const allowEdit = !personalCode && !isLoading;
  const channelSelectionRequired = (!!email || !!phone) && offersConsent;

  const form = useForm<ProfileInfoFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        offersConsent: Yup.boolean(),
        channelSms: Yup.boolean(),
        channelEmail: Yup.boolean(),
      }),
    ),
    initialValues: {
      offersConsent: false,
      channelSms: false,
      channelEmail: false,
      birthDate: birthDate,
      firstName: firstName,
      lastName: lastName,
    },
  });

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        return t('registerCard.saveProfileData.cardNotFoundOrInInvalidStateError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.CreatingOrUpdatingProfileFailed) {
        return t('registerCard.saveProfileData.creatingProfileFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (saveProfileQueryState.isSuccess) {
      dispatch(setTempToken(saveProfileQueryState.data?.tempToken));
      saveProfileQueryState.reset();
    }
  }, [
    saveProfileQueryState,
    saveProfileQueryState.isSuccess,
    saveProfileQueryState.data?.tempToken,
    dispatch,
    login,
    props,
    complete,
    executeRecaptcha,
    hasPasswordAuth,
  ]);

  useEffect(() => {
    if (completeQueryState.isSuccess) {
      dispatch(setFinalAuthResponse(completeQueryState.data));
      completeQueryState.reset();
      props.nextStep();
    }
  }, [completeQueryState.isSuccess, props, completeQueryState.data, completeQueryState, dispatch]);

  useEffect(() => {
    if (saveProfileQueryState.isError && saveProfileQueryState.error) {
      const error = saveProfileQueryState.error as ApiError;
      const errorMessage = getErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [saveProfileQueryState.isError, saveProfileQueryState.error, getErrorMessage, t]);

  useEffect(() => {
    if (completeQueryState.isError && completeQueryState.error) {
      const errorMessage = getErrorMessage(completeQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
    }
  }, [completeQueryState.isError, completeQueryState.error, getErrorMessage, t]);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('firstName', value);
    if (form.errors.firstName) {
      form.clearErrors();
    }
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('lastName', value);
    if (form.errors.lastName) {
      form.clearErrors();
    }
  };

  const handleDataIsCorrectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataIsCorrect(e.target.checked);
  };

  const handleOffersConsentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOffersConsent(e.target.checked);
  };

  const handleChannelSmsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChannelSms(e.target.checked);
  };

  const handleChannelEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChannelEmail(e.target.checked);
  };

  const handleFormSubmit = useCallback(
    async (values: ProfileInfoFormValues) => {
      if (channelSelectionRequired && !channelEmail && !channelSms) {
        toast(t('commonValidation.offerMethodRequiredError'), { type: 'error' });
        return;
      }

      async function completeAsync(tempTokenIn: string) {
        const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingComplete);
        complete({ tempToken: tempTokenIn, recaptchaToken: recaptchaToken });
      }

      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingSaveProfile);
      saveProfileQueryState.reset();
      saveProfile({
        tempToken: tempToken || '',
        recaptchaToken: recaptchaToken,
        birthDate: values.birthDate,
        firstName: values.firstName,
        lastName: values.lastName,
        offersConsent: offersConsent,
        channelSms: channelSms,
        channelEmail: channelEmail,
      })
        .unwrap()
        .then((response) => {
          completeAsync(response.tempToken || '');
        });
    },
    [
      executeRecaptcha,
      saveProfile,
      tempToken,
      saveProfileQueryState,
      offersConsent,
      channelSms,
      channelEmail,
      channelSelectionRequired,
      complete,
      t,
    ],
  );

  return (
    <Box
      sx={(theme) => ({
        '&, & form': {
          gap: '8px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          [theme.fn.largerThan(500)]: {
            alignItems: 'flex-start',
          },
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1),
          width: '100%',
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(2.5),
          marginBottom: theme.other.spacing(2),
          width: '60%',
        },
        '& .mantine-TextInput-root input, & .mantine-DatePicker-root input': {
          opacity: 1,
        },
      })}
    >
      <form onSubmit={isLoading ? undefined : form.onSubmit(handleFormSubmit)}>
        <Title
          sx={(theme) => ({
            width: '60%',
            color: theme.colors.brandGray,
            fontFamily: 'ProximaNovaBold',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.6rem',
              width: '100%',
            },
          })}
        >
          {t('profileLanding.profileInfoStep.title')}
        </Title>
        <Text
          sx={(theme) => ({
            marginTop: theme.other.spacing(2),
            color: '#828282',
            width: '60%',
            [theme.fn.smallerThan(600)]: {
              width: '100%',
            },
          })}
        >
          <Text>{t('profileLanding.profileInfoStep.description')}</Text>
        </Text>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: '24px',
            flexWrap: 'wrap',
            [theme.fn.smallerThan(960)]: {
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            },
            '& .formSection': {
              display: 'flex',
              width: '250px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              flexShrink: 0,
              color: 'red',
              [theme.fn.smallerThan(550)]: {
                width: '100%',
              },
            },
          })}
        >
          <Box className="formSection">
            <TextInput
              label={t('profileLanding.profileInfoStep.firstName')}
              disabled={!!personalCode && !allowEdit}
              onChange={handleFirstNameChange}
              error={form.errors.firstName}
              value={form.values.firstName || undefined}
            />
          </Box>
          <Box className="formSection">
            <TextInput
              label={t('profileLanding.profileInfoStep.lastName')}
              disabled={!!personalCode && !allowEdit}
              onChange={handleLastNameChange}
              error={form.errors.lastName}
              value={form.values.lastName || undefined}
            />
          </Box>
          <Box className="formSection">
            <DateInput
              label={t('profileLanding.profileInfoStep.birthdate')}
              placeholder={t('profileLanding.profileInfoStep.birthdate')}
              clearable={false}
              disabled={!allowEdit}
              value={new Date(birthDate)}
              sx={() => ({
                width: '100%',
              })}
            />
          </Box>
          <Box className="formSection">
            <TextInput label={t('profileLanding.profileInfoStep.personalCode')} disabled={true} value={personalCode} />
          </Box>
          <Box className="formSection">
            <TextInput label={t('profileLanding.profileInfoStep.email')} disabled={true} value={email} />
          </Box>
          <Box className="formSection">
            <TextInput label={t('profileLanding.profileInfoStep.phoneNumber')} disabled={true} value={phone} />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(6),

            alignItems: 'flex-start',
          })}
        >
          <Checkbox
            onChange={handleOffersConsentChange}
            checked={offersConsent}
            label={
              !!personalCode && featureFlags.birthdayOffersEnabled
                ? t('profileLanding.profileInfoStep.offersConsent')
                : t('profileLanding.profileInfoStep.offersConsentWithoutBirthday')
            }
            disabled={isLoading}
          ></Checkbox>
          <Marketing5RewardNotice accountId={accountId} />
          <Checkbox
            onChange={handleDataIsCorrectChange}
            checked={dataIsCorrect}
            required
            label={t('profileLanding.profileInfoStep.dataIsCorrect')}
            disabled={isLoading}
          ></Checkbox>
          <>
            <h3>
              <Text>
                {t('profileLanding.profileInfoStep.channelsTitle')}
                {channelSelectionRequired ? <span className={classes.required}>&nbsp;*</span> : <></>}
              </Text>
            </h3>
          </>
          <Checkbox
            onChange={handleChannelSmsChange}
            checked={channelSms}
            disabled={isLoading || !phone}
            label={t('profileLanding.profileInfoStep.channelSms')}
          ></Checkbox>
          <Checkbox
            onChange={handleChannelEmailChange}
            checked={channelEmail}
            disabled={isLoading || !email}
            label={t('profileLanding.profileInfoStep.channelEmail')}
          ></Checkbox>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(6),

            alignItems: 'flex-start',
          })}
        >
          <Checkbox
            checked={true}
            disabled
            label={
              <Trans i18nKey="profileLanding.profileInfoStep.agreeToLoyaltyTerms.label">
                I agree to loyalty
                <Anchor
                  href={t('profileLanding.profileInfoStep.agreeToLoyaltyTerms.TCUrl')}
                  target="_blank"
                  sx={() => ({
                    '&': {
                      fontSize: '0.9rem',
                    },
                  })}
                >
                  terms and conditions
                </Anchor>
                .
              </Trans>
            }
          ></Checkbox>
        </Box>
        <Button type="submit" disabled={!dataIsCorrect || isLoading}>
          {t('profileLanding.continue')}
        </Button>
      </form>
    </Box>
  );
};

export default ProfileInfo;
