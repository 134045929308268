import { Box, Text } from '@mantine/core';
import { ReactNode } from 'react';
import HelpTooltip from '../../HelpTooltip';

interface CheckboxLabelWithTooltipProps {
  label: string | ReactNode;
  tooltipLabel: string;
}

const CheckboxLabelWithTooltip = (props: CheckboxLabelWithTooltipProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.other.spacing(1),
      })}
    >
      <Text
        sx={{
          marginTop: '2px',
          fontSize: '0.9rem',
        }}
      >
        {props.label}
      </Text>

      <HelpTooltip label={props.tooltipLabel} />
    </Box>
  );
};

export default CheckboxLabelWithTooltip;
