import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import CardNumberRow from './CardNumberRow';
import CustomerNameRow from './CustomerNameRow';
import CustomerDateOfBirthRow from './CustomerDateOfBirthAndPersonalCodeRow';
import CustomerContactRow from './CustomerContactRow';
import PersonalOffersAgreementsGroup from './PersonalOffersAgreementsGroup';
import OfferMethodsAgreementsGroup from './OfferMethodsAgreementsGroup';
import EReceiptsAgreementsGroup from './EReceiptsAgreementsGroup';
import GeneralAgreementsGroup from './GeneralAgreementsGroup';
import CustomerCommunicationLanguageRow from './CustomerCommunicationLanguageRow';
import {
  getProfileDataFromForm,
  getProfileFormValues,
  getUserConsentsFromForm,
  ProfileDataFormValues,
} from '../../../utility/saveProfileUtils';
import Button from '../../../components/Button';
import PersonalData from '../../../models/PersonalData';
import useRoutes from '../../../i18n/useRoutes';
import { useAppDispatch } from '../../../hooks/store';
import { ESTONIAN_MOBILE_NUMBER_REGEX } from '../../../utility';
import { setCardAccountStage, setUnverifiedEmail, setVerificationToken } from '../../../store/cardSlice';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import { setPersonalData } from '../../../store/userSlice';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useCreateProfileDataMutation, useUpdateProfileDataMutation } from '../../../store/api/profileApi';
import Checkbox from '../../../components/form/Checkbox/Checkbox';

interface SaveProfileDataFormProps {
  cardNumber?: string;
  personalData?: PersonalData | null;
  onChangeToPasswordView?: () => void;
}

const SaveProfileDataForm = (props: SaveProfileDataFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dataIsCorrect, setDataIsCorrect] = useState(false);

  const formContainerRef = useRef<HTMLDivElement>(null);
  const routes = useRoutes();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [createProfileData, createProfileQueryState] = useCreateProfileDataMutation();
  const [updateProfileData, updateProfileQueryState] = useUpdateProfileDataMutation();

  const fieldDisabled = createProfileQueryState.isLoading || updateProfileQueryState.isLoading;

  const form = useForm<ProfileDataFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        dateOfBirth: Yup.string().required(t('registerCard.saveProfileData.dateOfBirthRequiredError')),
        email: Yup.string()
          .email(t('landing.loginForm.emailInvalidError'))
          .required(t('registerCard.saveProfileData.emailRequiredError')),
        phoneNumber: Yup.string().matches(ESTONIAN_MOBILE_NUMBER_REGEX, t('landing.loginForm.mobileInvalidError')),
        personalCode: Yup.string(),
        language: Yup.string(),
        agreementsPersonalOffers: Yup.array(),
        agreementsOfferMethods: Yup.array(),
        agreementsEReceipts: Yup.array(),
        agreementGeneral: Yup.array(),
      }),
    ),
    initialValues: getProfileFormValues(props.personalData, false),
  });

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);
  // eslint-disable-next-line
  const setFormValues = useCallback(form.setValues, []);

  const formValues = form.values;
  const formErrors = form.errors;

  const isOfferMethodRequired =
    (!!formValues.email || !!formValues.phoneNumber) && formValues.agreementsPersonalOffers.length > 0;

  const getSaveProfileErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        return t('registerCard.saveProfileData.cardNotFoundOrInInvalidStateError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.CreatingOrUpdatingProfileFailed) {
        return t('registerCard.saveProfileData.creatingProfileFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const getUpdateProfileErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.BirthDateChangeExhausted) {
        return t('myProfile.profileDataForm.birthDateExhaustedError');
      }
      if (errorCode === ErrorCode.CreatingOrUpdatingProfileFailed) {
        return t('myProfile.profileDataForm.profileUpdateError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    setFormValues(getProfileFormValues(props.personalData, false));
  }, [props.personalData, setFormValues]);

  useEffect(() => {
    if (createProfileQueryState.isSuccess) {
      dispatch(setVerificationToken(createProfileQueryState.data.verificationToken));
      dispatch(setCardAccountStage('verifyEmail'));
      navigate(routes.createAccount);
      toast(t('registerCard.saveProfileData.creatingProfileSuccess'), { type: 'success' });
      window.scrollTo(0, 0);
    }
  }, [createProfileQueryState.isSuccess, createProfileQueryState.data, routes.createAccount, dispatch, navigate, t]);

  useEffect(() => {
    if (createProfileQueryState.isError && createProfileQueryState.error) {
      const error = createProfileQueryState.error as ApiError;
      const errorMessage = getSaveProfileErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [createProfileQueryState.isError, createProfileQueryState.error, getSaveProfileErrorMessage, t]);

  useEffect(() => {
    if (createProfileQueryState.isError && createProfileQueryState.error) {
      const errorCode = (createProfileQueryState.error as ApiError).data?.message;

      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        setFieldError('dateOfBirth', t('registerCard.saveProfileData.dateOfBirthIncorrectError'));
        formContainerRef.current?.scrollIntoView();
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        if (formValues.email) {
          setFieldError('email', t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError'));
        }
        if (formValues.phoneNumber) {
          setFieldError('phoneNumber', t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError'));
        }
        formContainerRef.current?.scrollIntoView();
      }
    }
  }, [createProfileQueryState.isError, createProfileQueryState.error, formValues, setFieldError, t]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      formContainerRef.current?.scrollIntoView();
    }
  }, [formErrors]);

  useEffect(() => {
    if (updateProfileQueryState.isSuccess) {
      dispatch(setPersonalData(updateProfileQueryState.data));
      toast(t('myProfile.profileDataForm.profileUpdateSuccess'), { type: 'success' });
      window.scrollTo(0, 0);
    }
  }, [updateProfileQueryState.isSuccess, updateProfileQueryState.data, dispatch, t]);

  useEffect(() => {
    if (updateProfileQueryState.isError) {
      const error = updateProfileQueryState.error as ApiError;
      const errorCode = error.data?.message;
      const errorMessage = getUpdateProfileErrorMessage(error);
      toast(errorMessage, { type: 'error' });

      if (errorCode === ErrorCode.BirthDateChangeExhausted) {
        setFieldError('dateOfBirth', t('myProfile.profileDataForm.birthDateExhaustedError'));
      }
    }
  }, [updateProfileQueryState.isError, updateProfileQueryState.error, setFieldError, getUpdateProfileErrorMessage, t]);

  const handleResetFields = () => {
    createProfileQueryState.reset();
    updateProfileQueryState.reset();
    form.clearErrors();
  };

  const handleCreateProfileFormSubmit = useCallback(
    async (values: ProfileDataFormValues) => {
      if (values.agreementGeneral.length === 0) {
        return setFieldError('agreementGeneral', t('registerCard.saveProfileData.agreeWithLoyaltyTermsRequiredError'));
      }

      const offerMethodsSelected = values.agreementsOfferMethods.length > 0;
      if (isOfferMethodRequired && !offerMethodsSelected) {
        return setFieldError('agreementsOfferMethods', t('commonValidation.offerMethodRequiredError'));
      }
      if (executeRecaptcha) {
        const profileData = getProfileDataFromForm(values);
        const consents = getUserConsentsFromForm(values);
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.ProfileDataCreate);

        createProfileData({
          cardNumber: props.cardNumber || '',
          profileData,
          consents,
          recaptchaToken,
        });
        dispatch(setUnverifiedEmail(formValues.email));
      }
    },
    [
      executeRecaptcha,
      createProfileData,
      setFieldError,
      dispatch,
      t,
      props.cardNumber,
      formValues,
      isOfferMethodRequired,
    ],
  );

  const handleUpdateProfileFormSubmit = (values: ProfileDataFormValues) => {
    if (values.agreementGeneral.length === 0) {
      return setFieldError('agreementGeneral', t('registerCard.saveProfileData.agreeWithLoyaltyTermsRequiredError'));
    }

    const offerMethodsSelected = values.agreementsOfferMethods.length > 0;
    if (isOfferMethodRequired && !offerMethodsSelected) {
      return setFieldError('agreementsOfferMethods', t('commonValidation.offerMethodRequiredError'));
    }

    const profileData = getProfileDataFromForm(values, { update: true });
    const consents = getUserConsentsFromForm(values);

    updateProfileData({
      cardNumber: props.cardNumber || '',
      profileData,
      consents,
    });
  };

  const navigateToLogin = () => {
    navigate(routes.login);
  };

  const handleDataIsCorrectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataIsCorrect(e.target.checked);
  };

  return (
    <Box ref={formContainerRef}>
      <form
        noValidate
        onSubmit={form.onSubmit(props.personalData ? handleUpdateProfileFormSubmit : handleCreateProfileFormSubmit)}
      >
        <CardNumberRow cardNumber={props.cardNumber} />
        <CustomerNameRow form={form} disabled={fieldDisabled} />
        <CustomerDateOfBirthRow
          form={form}
          disabled={fieldDisabled}
          isUpdate={Boolean(props.personalData)}
          onReset={handleResetFields}
        />
        <CustomerContactRow
          form={form}
          disabled={fieldDisabled}
          personalData={props.personalData}
          onReset={handleResetFields}
        />
        <CustomerCommunicationLanguageRow form={form} />

        {props.personalData && !props.personalData.profileData.personalCode && props.onChangeToPasswordView && (
          <Box sx={(theme) => ({ marginTop: theme.other.spacing(6) })}>
            <Box sx={(theme) => ({ marginBottom: theme.other.spacing(1.5) })}>
              <Text
                sx={(theme) => ({
                  color: theme.colors.maximaBlueDefault,
                  fontSize: '0.9rem',
                  fontFamily: 'ProximaNovaSemibold',
                  letterSpacing: '0.01em',
                })}
              >
                {t('myProfile.changePassword.subHeaderTitle')}
              </Text>
            </Box>
            <Box
              sx={(theme) => ({
                [theme.fn.smallerThan(500)]: {
                  display: 'flex',
                  '& > button': {
                    flex: '1 0 auto',
                  },
                },
              })}
            >
              <Button variant="outline" onClick={props.onChangeToPasswordView} disabled={fieldDisabled}>
                {t('myProfile.profileDataForm.changePassword')}
              </Button>
            </Box>
          </Box>
        )}

        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(6),
            alignItems: 'flex-start',
          })}
        >
          <Checkbox
            onChange={handleDataIsCorrectChange}
            checked={dataIsCorrect}
            required
            label={t('myProfile.dataIsCorrect')}
          ></Checkbox>
        </Box>

        <PersonalOffersAgreementsGroup form={form} disabled={fieldDisabled} isUpdate={Boolean(props.personalData)} />
        <OfferMethodsAgreementsGroup form={form} disabled={fieldDisabled} required={isOfferMethodRequired} />
        <EReceiptsAgreementsGroup form={form} disabled={fieldDisabled} />
        <GeneralAgreementsGroup form={form} disabled={fieldDisabled || Boolean(props.personalData)} />

        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(6),
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.other.spacing(2),
            [theme.fn.smallerThan(500)]: {
              flexDirection: 'column-reverse',
            },
          })}
        >
          {!props.personalData && (
            <Button variant="outline" onClick={navigateToLogin} disabled={fieldDisabled}>
              {t('cardSettings.cancel')}
            </Button>
          )}
          <Button type="submit" loading={fieldDisabled} disabled={!dataIsCorrect}>
            {t('registerCard.saveProfileData.saveProfile')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SaveProfileDataForm;
