import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Anchor } from '@mantine/core';
import CheckboxGroup from '../../../components/form/Checkbox/CheckboxGroup';
import CheckboxLabelWithTooltip from '../../../components/form/Checkbox/CheckboxLabelWithTooltip';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';

interface GeneralAgreementsGroupProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
}

const GeneralAgreementsGroup = ({ form, disabled }: GeneralAgreementsGroupProps) => {
  const { t } = useTranslation();

  const handleGeneralAgreementChange = (value: string[]) => form.setFieldValue('agreementGeneral', value);

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(6),
      })}
    >
      <CheckboxGroup
        value={form.values.agreementGeneral}
        onChange={handleGeneralAgreementChange}
        required
        error={form.errors.agreementGeneral}
        orientation="vertical"
        label={t('registerCard.saveProfileData.agreementGeneralTitle')}
        options={[
          {
            value: 'agreeWithLoyaltyTerms',
            label: (
              <CheckboxLabelWithTooltip
                label={
                  <Trans i18nKey="registerCard.saveProfileData.agreeWithLoyaltyTerms">
                    I agree to loyalty
                    <Anchor
                      href={t('registerCard.saveProfileData.agreeWithLoyaltyTermsTCUrl')}
                      target="_blank"
                      sx={() => ({
                        '&': {
                          fontSize: '0.9rem',
                        },
                      })}
                    >
                      terms and conditions
                    </Anchor>
                    .
                  </Trans>
                }
                tooltipLabel={t('registerCard.saveProfileData.agreeWithLoyaltyTermsTooltip')}
              />
            ),
            disabled,
          },
        ]}
      />
    </Box>
  );
};

export default GeneralAgreementsGroup;
