import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from '../../../components/form/Checkbox/CheckboxGroup';
import CheckboxLabelWithTooltip from '../../../components/form/Checkbox/CheckboxLabelWithTooltip';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';

interface OfferMethodsAgreementsGroupProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
  required?: boolean;
}

const OfferMethodsAgreementsGroup = ({ form, disabled, required }: OfferMethodsAgreementsGroupProps) => {
  const { t } = useTranslation();
  const formValues = form.values;

  // eslint-disable-next-line
  const setFieldValue = useCallback(form.setFieldValue, []);

  useEffect(() => {
    if (!formValues.email && formValues.agreementsOfferMethods.includes('agreeToGetEmail')) {
      const filteredConsents = formValues.agreementsOfferMethods.filter((consent) => consent !== 'agreeToGetEmail');
      setFieldValue('agreementsOfferMethods', filteredConsents);
    }
    if (!formValues.phoneNumber && formValues.agreementsOfferMethods.includes('agreeToGetSms')) {
      const filteredConsents = formValues.agreementsOfferMethods.filter((consent) => consent !== 'agreeToGetSms');
      setFieldValue('agreementsOfferMethods', filteredConsents);
    }
  }, [formValues, setFieldValue]);

  const handleOfferMethodsChange = (value: string[]) => form.setFieldValue('agreementsOfferMethods', value);

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(6),
      })}
    >
      <CheckboxGroup
        orientation="vertical"
        value={form.values.agreementsOfferMethods}
        onChange={handleOfferMethodsChange}
        label={t('registerCard.saveProfileData.agreementsOfferMethodsTitle')}
        required={required}
        error={form.errors.agreementsOfferMethods}
        options={[
          {
            value: 'agreeToGetSms',
            label: (
              <CheckboxLabelWithTooltip
                label={t('registerCard.saveProfileData.agreeToGetSms')}
                tooltipLabel={t('registerCard.saveProfileData.agreeToGetSmsTooltip')}
              />
            ),
            disabled: !form.values.phoneNumber || disabled,
          },
          {
            value: 'agreeToGetEmail',
            label: t('registerCard.saveProfileData.agreeToGetEmail'),
            disabled: !form.values.email || disabled,
          },
        ]}
      />
    </Box>
  );
};

export default OfferMethodsAgreementsGroup;
