import { Box, Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import TextInput from '../../../components/form/TextInput';
import { useAppDispatch } from '../../../hooks/store';
import PersonalData from '../../../models/PersonalData';
import { setPromptContact } from '../../../store/userSlice';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';

interface CustomerContactRowProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
  personalData?: PersonalData | null;
  onReset: () => void;
}

const CustomerContactRow = ({ form, disabled, personalData, onReset }: CustomerContactRowProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleAddOrChangeEmail = () => {
    dispatch(
      setPromptContact({
        type: 'email',
        action: personalData?.profileData.email ? 'change' : 'add',
      }),
    );
  };

  const handleVerifyEmail = () => {
    dispatch(setPromptContact({ type: 'email', action: 'verify' }));
  };

  const handleAddOrChangePhoneNumber = () => {
    dispatch(
      setPromptContact({
        type: 'phoneNumber',
        action: personalData?.profileData.phoneNumber ? 'change' : 'add',
      }),
    );
  };

  const handleVerifyPhoneNumber = () => {
    dispatch(setPromptContact({ type: 'phoneNumber', action: 'verify' }));
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('phoneNumber', value);
    if (value === '' && form.values.agreementsOfferMethods.includes('agreeToGetSms')) {
      const newAgreements = form.values.agreementsOfferMethods.filter((agreement) => agreement !== 'agreeToGetSms');
      form.setFieldValue('agreementsOfferMethods', newAgreements);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('email', e.target.value);
    if (form.errors.email || form.errors.phoneNumber) {
      onReset();
    }
  };

  return (
    <Grid
      gutter="lg"
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Grid.Col sm={6}>
        <TextInput
          {...form.getInputProps('email')}
          label={t('landing.loginForm.email')}
          placeholder={t('landing.loginForm.email')}
          onChange={handleEmailChange}
          required
          disabled={Boolean(personalData) || disabled}
        />
        {personalData && (
          <Box
            sx={(theme) => ({
              marginTop: theme.other.spacing(2),
              display: 'flex',
              gap: theme.other.spacing(1),
              [theme.fn.smallerThan(500)]: {
                flexDirection: 'column',
              },
            })}
          >
            <Button variant={personalData?.profileData.email ? 'outline' : 'filled'} onClick={handleAddOrChangeEmail}>
              {personalData?.profileData.email ? t('myProfile.profileDataForm.edit') : t('myProfile.changeContact.add')}
            </Button>
            {!personalData?.profileData?.emailVerified && personalData?.profileData?.email && (
              <Button onClick={handleVerifyEmail}>{t('myProfile.changePassword.confirm')}</Button>
            )}
          </Box>
        )}
      </Grid.Col>
      <Grid.Col sm={6}>
        <PhoneNumberInput
          name="phoneNumber"
          label={t('landing.loginForm.mobile')}
          onChange={handlePhoneNumberChange}
          value={form.values.phoneNumber}
          error={form.errors.phoneNumber}
          required
          disabled={Boolean(personalData) || disabled}
        />
        {personalData && (
          <Box
            sx={(theme) => ({
              marginTop: theme.other.spacing(2),
              display: 'flex',
              gap: theme.other.spacing(1),
              [theme.fn.smallerThan(500)]: {
                flexDirection: 'column',
              },
            })}
          >
            <Button
              variant={personalData?.profileData.phoneNumber ? 'outline' : 'filled'}
              onClick={handleAddOrChangePhoneNumber}
            >
              {personalData?.profileData.phoneNumber
                ? t('myProfile.profileDataForm.edit')
                : t('myProfile.changeContact.add')}
            </Button>
            {!personalData?.profileData?.phoneNumberVerified && personalData?.profileData?.phoneNumber && (
              <Button onClick={handleVerifyPhoneNumber}>{t('myProfile.changePassword.confirm')}</Button>
            )}
          </Box>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default CustomerContactRow;
